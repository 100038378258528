/*#if _GLOB_EAGER

import researchFallback from "~/assets/research/research_project_0.png";

export function researchThumb(id: number): string {
  const researchThumbs = import.meta.globEager("/src/assets/research/*.png");
  return researchThumbs[`/src/assets/research/research_project_${id}.png`]?.default || researchFallback;
}

import shipFallback from "~/assets/ship-thumbs/ship_000_thumb.png";

export function shipThumb(id: number): string {
  const shipThumbs = import.meta.globEager("/src/assets/ship-thumbs/*.png");
  return shipThumbs[
    `/src/assets/ship-thumbs/ship_${String(id || 0).padStart(
      3,
      "0"
    )}_thumb.png`
  ]?.default || shipFallback;
}

import officerFallback from "~/assets/officers/Character_0.png";

export function officerThumb(id: number): string {
  const officerThumbs = import.meta.globEager("/src/assets/officers/*.png");
  return officerThumbs[`/src/assets/officers/Character_${id}.png`]?.default || officerFallback;
}

import resourceFallback from "~/assets/resources/resource_icon_1_masked.png";

export function resourceThumb(id?: number): string {
  const resourceThumbs = import.meta.globEager("/src/assets/resources/*.png");
  return resourceThumbs[`/src/assets/resources/resource_icon_${id}_masked.png`]?.default || resourceFallback;
}


//#else */
const researchThumbs = import.meta.glob("/src/assets/research/*.png");
const shipThumbs = import.meta.glob("/src/assets/ship-thumbs/*.png");
const officerThumbs = import.meta.glob("/src/assets/officers/*.png");
const resourceThumbs = import.meta.glob("/src/assets/resources/*.png");

import { reactive, Ref, ref } from "@vue/reactivity";

function resolvePromisedThing(
  f: Function | Object | String | undefined,
  id: number,
  target: Map<Number, Ref<String>>,
  fallback: String
) {
  if (f && f instanceof Function) {
    f().then((e: { default: string }) => {
      (target.get(id) as Ref<String>).value = e.default;
    });
    target.set(id, ref(fallback));
  } else if (f && (f instanceof String || typeof f === "string")) {
    target.set(id, ref(f));
  } else if (f && typeof f === "object") {
    target.set(id, ref((f as any).default));
  } else {
    target.set(id, ref(fallback));
  }
}

import researchFallback from "~/assets/research/research_project_0.png";

const researchThumbImages: Map<Number, Ref<String>> = reactive(new Map());

export function researchThumb(id: number): Ref<string> {
  if (researchThumbImages.has(id)) {
    return researchThumbImages.get(id) as Ref<string>;
  }

  const thumbPromise =
    researchThumbs[`/src/assets/research/research_project_${id}.png`];

  resolvePromisedThing(thumbPromise, id, researchThumbImages, researchFallback);
  return researchThumbImages.get(id) as Ref<string>;
}

import shipFallback from "~/assets/ship-thumbs/ship_000_thumb.png";

const shipThumbImages: Map<Number, Ref<String>> = reactive(new Map());

export function shipThumb(id: number): Ref<string> {
  if (shipThumbImages.has(id)) {
    return shipThumbImages.get(id) as Ref<string>;
  }

  const thumbPromise =
    shipThumbs[
    `/src/assets/ship-thumbs/ship_${String(id || 0).padStart(
      3,
      "0"
    )}_thumb.png`
    ];

  resolvePromisedThing(thumbPromise, id, shipThumbImages, shipFallback);
  return shipThumbImages.get(id) as Ref<string>;
}

import officerFallback from "~/assets/officers/Character_0.png";

const officerThumbImages: Map<Number, Ref<String>> = reactive(new Map());

export function officerThumb(id: number): Ref<string> {
  if (officerThumbImages.has(id)) {
    return officerThumbImages.get(id) as Ref<string>;
  }

  const thumbPromise =
    officerThumbs[`/src/assets/officers/Character_${id}.png`];

  resolvePromisedThing(thumbPromise, id, officerThumbImages, officerFallback);
  return officerThumbImages.get(id) as Ref<string>;
}

import resourceFallback from "~/assets/resources/resource_icon_1_masked.png";

const resourceThumbImages: Map<Number, Ref<String>> = reactive(new Map());

export function resourceThumb(id?: number): Ref<string> {
  if (!id) {
    return ref(resourceFallback);
  }

  if (resourceThumbImages.has(id)) {
    return resourceThumbImages.get(id) as Ref<string>;
  }

  const thumbPromise =
    resourceThumbs[`/src/assets/resources/resource_icon_${id}_masked.png`];

  resolvePromisedThing(thumbPromise, id, resourceThumbImages, resourceFallback);
  return resourceThumbImages.get(id) as Ref<string>;
}

//#endif

import buildingFallback from "~/assets/buildings/starbase_0.png";

export function buildingThumb(id: number): string {
  if (
    id == 2 ||
    id == 3 ||
    id == 50 ||
    id == 51 ||
    id == 52 ||
    id == 53 ||
    id == 54
  ) {
    id = 1;
  }

  if (
    id == 6 ||
    id == 7 ||
    id == 56 ||
    id == 57 ||
    id == 58 ||
    id == 59 ||
    id == 60
  ) {
    id = 5;
  }

  if (
    id == 10 ||
    id == 11 ||
    id == 62 ||
    id == 63 ||
    id == 64 ||
    id == 65 ||
    id == 66
  ) {
    id = 9;
  }

  const buildingThumbs = import.meta.globEager("/src/assets/buildings/*.png");

  return buildingThumbs[`/src/assets/buildings/starbase_${id}.png`]?.default || buildingFallback;
}


import officerAbilityFallback from "~/assets/icons/abilities/OfficerAbility_ico_ability_type_small_0.png";

export function officerAbilityThumb(id: number): string {
  const officerAbilityThumbs = import.meta.globEager('/src/assets/icons/abilities/*.png');
  return officerAbilityThumbs[
    `/src/assets/icons/abilities/OfficerAbility_ico_ability_type_small_${id}.png`
  ]?.default || officerAbilityFallback;
}

export function consumableThumb(id: number): string {
  const consumableThumbs = import.meta.globEager('/src/assets/consumables/*.png');
  return consumableThumbs[
    `/src/assets/consumables/consumable_icon_${id}_masked.png`
  ]?.default || resourceFallback;
}

export function skinThumb(id: number | string, type: string): string {
  const consumableThumbs = import.meta.globEager('/src/assets/skins/*.png');
  console.log(`/src/assets/skins/ship_${type}_${id}_card.png`);
  return consumableThumbs[
    `/src/assets/skins/ship_${type}_${id.toString().padStart(
      3,
      "0"
    )}_card.png`
  ]?.default || resourceFallback;
}
